import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Button, Content, CTABox, Spacer } from 'components';
export const _frontmatter = {
  "title": "Security"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content mdxType="Content">
	<Spacer height={100} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
	<h1>Security</h1>
	<p>
		A truly secure and private app was one of the main foundations of our
		app. We take this seriously, so our app is secure by default.
	</p>
	<h2>Security by default</h2>
	<p>
		Whenever you're using a free or premium version, notes stay private, and
		their content is visible only to you.
	</p>
	<p>
		If you're using a free version, notes stay saved in your browser's local
		storage. They're not sent anywhere, and they work offline.
	</p>
	<p>More than that, they can be read only by our app.</p>
	<h2>Safe cloud copy of your notes</h2>
	<p>
		To get the note synchronization across all your devices, we need to
		store them somewhere. But we don't keep them in plain text, silly!
	</p>
	<p>
		Instead, we're using your password to create a unique encryption key.
		This one is used to encrypt and decrypt your notes, and it's also
		happening on your local machine.
	</p>
	<h2>The encryption flow</h2>
	<p>
		When you log in to the app or set up your account password, we create an
		encryption key out of it, and we store it in your browser for later use.
		It works like a super-secure, long, random(ish) password to your notes.
		We also display it to you, and you should <b>treat it as a password</b>.
		Store it somewhere else, like a password manager for later use.
	</p>
	<p>
		If you forgot your primary password, this encryption key would be the
		only way to decrypt your notes. If you lost it, you'd need a quantum
		computer to see your notes again. Which is not going to happen in a
		couple of years, sorry.
	</p>
	<p>
		On our servers, we keep only an encrypted version of your primary
		password and hashes of your notes. You keep the secret sauce, and no one
		else can read your notes. Not even us.
	</p>
	<p>
		What happens when you change your password? We decrypt all your notes
		with your old password and encrypt them again with a new one. Easy.
	</p>
	<h3>Note hash example</h3>
	<p>If your note's content is this:</p>
	<code>Lorem ipsum dolor sit amet</code>
	<p>This is what we store in our database:</p>
	<code>Ocer0c72ehLwyrbhnaSLQMFgdcRIMX1Kg2J22BhKl1k=</code>
	<p>And only we know the key 😈</p>
	<p>
		If you have any questions on how MadNotes work, drop by our{' '}
		<a href="https://github.com/BracketSpace/MadNotes/discussions" title="MadNotes Community on GitHub">
			Community
		</a>{' '}
		and ask away!
	</p>
    </Content>
    <Spacer height={60} responsive={{
      md: 40,
      sm: 30
    }} mdxType="Spacer" />
    <CTABox color="banana99" description="Enough of theory, proceed to practice!" heading="Get your notes a safe home they deserve" mdxType="CTABox">
	<Button arrow="right" color="berry99" to="https://use.madnotes.app" type="plain" mdxType="Button">
		Start noting
	</Button>
	<Button color="berry99" to="/#pricing" type="ghost" gtag={['event', 'click', {
        event_label: 'Pricing'
      }]} mdxType="Button">
		See Pricing
	</Button>
    </CTABox>
    <Spacer height={100} responsive={{
      md: 80,
      sm: 60
    }} mdxType="Spacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      